import React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import TimeFormatConstants from '@lib/constants/time-format.constant'
import { TimeHelper } from '@lib/helpers'

interface Props {
  time: Date
  files: number
  filesDifference: number | null
}

function formatTime(time: Date) {
  return TimeHelper.getDateWithTimezone(time.getTime()).format(
    TimeFormatConstants.SHORT_DATETIME_FORMAT
  )
}

function FilesDifference({
  filesDifference,
}: {
  filesDifference: number | null
}) {
  if (filesDifference === null || filesDifference === 0) {
    return null
  }

  const isIncreased = filesDifference > 0
  const pluralizedFiles = Math.abs(filesDifference) === 1 ? 'file' : 'files'

  return (
    <Stack direction="row" gap="5px">
      <Typography
        variant="body1"
        color={isIncreased ? 'var(--red600)' : 'var(--green600)'}
      >
        {isIncreased && '+'} {filesDifference} {pluralizedFiles}
      </Typography>
      <Typography variant="body1" color="textSecondary">
        since previous scan
      </Typography>
    </Stack>
  )
}

export function CustomTooltip({ time, files, filesDifference }: Props) {
  return (
    <Stack paddingY="8px" paddingX="20px">
      <Typography variant="caption" fontSize="11px" color="var(--grey400)">
        {formatTime(time)}
      </Typography>
      <Stack direction="row" gap="12px" alignItems="center">
        <Typography variant="h5" fontSize="22px" fontWeight="500">
          {files}
        </Typography>
        <Typography variant="body1" fontSize="16px">
          High entropy files
        </Typography>
      </Stack>
      <FilesDifference filesDifference={filesDifference} />
    </Stack>
  )
}

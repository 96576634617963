import { Finding } from '@lib/models/anomalies/finding.model'
import { Source } from '@lib/models/anomalies/source.model'
import { Scan } from './scan.model'

export enum AnomalyKind {
  ANOMALY_KIND_HIGH_ENTROPY_FILES,
}

interface AnomalyAttrs {
  id: string
  kind: AnomalyKind
  findings: Array<Finding>
  scan: Scan
  source: Source
  totalNumberOfFiles: number
  createdAt: Date
}

export class Anomaly {
  // UUID of the Anomaly
  #id: string

  // A kind of the Anomaly
  #kind: AnomalyKind

  // A list of findings
  #findings: Array<Finding>

  // A scan that detected the Anomaly
  #scan: Scan

  #source: Source

  #totalNumberOfFiles: number

  // The Anomaly creation timestamp
  #createdAt: Date

  constructor(attrs: AnomalyAttrs) {
    this.#id = attrs.id
    this.#kind = attrs.kind
    this.#findings = attrs.findings
    this.#scan = attrs.scan
    this.#source = attrs.source
    this.#totalNumberOfFiles = attrs.totalNumberOfFiles
    this.#createdAt = attrs.createdAt
  }

  get id() {
    return this.#id
  }

  get kind() {
    return this.#kind
  }

  get findings() {
    return this.#findings
  }

  get scan() {
    return this.#scan
  }

  get source() {
    return this.#source
  }

  get assetId() {
    const isAsset = this.#source.getAsset()
    if (isAsset) {
      return this.#source.getAsset()?.getId()
    }

    const isBackup = this.#source.getBackup()

    if (isBackup) {
      return this.#source.getBackup()?.getAssetId()
    }

    const isAssetItem = this.#source.getAssetItem()

    if (isAssetItem) {
      return this.#source.getAssetItem()?.getAssetId()
    }
  }

  get totalNumberOfFiles() {
    return this.#totalNumberOfFiles
  }

  get createdAt() {
    return this.#createdAt
  }
}

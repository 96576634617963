import { Anomaly as AnomalyPb } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/models/v1/anomalies/anomaly_pb'
import {
  Anomaly,
  AnomalyKind,
  Asset,
  AssetItem,
  Backup,
  Finding,
  FindingConfidence,
  RansomwareScan,
  Scan,
  Source,
} from '@lib/models/anomalies'

export class AnomalyTransformer {
  #anomalyPb: AnomalyPb.AsObject

  constructor(anomalyPb: AnomalyPb.AsObject) {
    this.#anomalyPb = anomalyPb
  }

  transform() {
    return new Anomaly({
      id: this.#anomalyPb.id,
      kind: this.#transformAnomalyKind(),
      findings: this.#transformFindings(),
      scan: new Scan({
        ransomwareScan: new RansomwareScan(
          this.#anomalyPb.scan?.ransomwareScanId
        ),
      }),
      source: this.#transformSource(),
      createdAt: new Date(this.#anomalyPb.createdAt!.seconds * 1000),
      totalNumberOfFiles: this.#anomalyPb.totalNumberOfFiles,
    })
  }

  #transformSource(): Source {
    const asset = new Asset(this.#anomalyPb.source?.asset?.id)

    const assetItem = new AssetItem(
      this.#anomalyPb.source?.assetItem?.id,
      this.#anomalyPb.source?.assetItem?.assetId
    )

    const backupId = this.#anomalyPb.source?.backup?.id

    const isAssetBackup = Boolean(this.#anomalyPb.source?.backup?.asset)

    const backup = isAssetBackup
      ? new Backup(
          backupId,
          new Asset(this.#anomalyPb.source?.backup?.asset?.id)
        )
      : new Backup(
          backupId,
          new AssetItem(
            this.#anomalyPb.source?.backup?.assetItem?.id,
            this.#anomalyPb.source?.backup?.assetItem?.assetId
          )
        )

    return new Source(asset, assetItem, backup)
  }

  #transformAnomalyKind() {
    const mapping: Record<AnomalyPb.AnomalyKind, AnomalyKind> = {
      [AnomalyPb.AnomalyKind.ANOMALY_KIND_HIGH_ENTROPY_FILES]:
        AnomalyKind.ANOMALY_KIND_HIGH_ENTROPY_FILES,
    }

    return mapping[this.#anomalyPb.kind]
  }

  #transformFindings(): Array<Finding> {
    const confidenceMapping: Record<
      AnomalyPb.FindingConfidence,
      FindingConfidence
    > = {
      [AnomalyPb.FindingConfidence.FINDING_CONFIDENCE_ENCRYPTED]:
        FindingConfidence.ENCRYPTED,
      [AnomalyPb.FindingConfidence.FINDING_CONFIDENCE_SUSPICIOUS]:
        FindingConfidence.SUSPICIOUS,
    }

    return this.#anomalyPb.findingsList.map((finding) => {
      return new Finding({
        confidence: confidenceMapping[finding.confidence],
        numberOfFiles: finding.numberOfFiles,
        path: finding.path,
        totalSize: finding.totalSize,
      })
    })
  }
}
